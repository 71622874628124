// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-basic-packing-js": () => import("./../src/pages/BasicPacking.js" /* webpackChunkName: "component---src-pages-basic-packing-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/Blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-post-js": () => import("./../src/pages/BlogPost.js" /* webpackChunkName: "component---src-pages-blog-post-js" */),
  "component---src-pages-commercial-moving-js": () => import("./../src/pages/CommercialMoving.js" /* webpackChunkName: "component---src-pages-commercial-moving-js" */),
  "component---src-pages-coronavirus-js": () => import("./../src/pages/coronavirus.js" /* webpackChunkName: "component---src-pages-coronavirus-js" */),
  "component---src-pages-elements-js": () => import("./../src/pages/Elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-full-packing-js": () => import("./../src/pages/FullPacking.js" /* webpackChunkName: "component---src-pages-full-packing-js" */),
  "component---src-pages-how-it-works-js": () => import("./../src/pages/HowItWorks.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loading-and-unloading-js": () => import("./../src/pages/LoadingAndUnloading.js" /* webpackChunkName: "component---src-pages-loading-and-unloading-js" */),
  "component---src-pages-long-distance-movers-js": () => import("./../src/pages/LongDistanceMovers.js" /* webpackChunkName: "component---src-pages-long-distance-movers-js" */),
  "component---src-pages-moving-faq-js": () => import("./../src/pages/MovingFaq.js" /* webpackChunkName: "component---src-pages-moving-faq-js" */)
}

